import React from "react";
import { graphql } from "gatsby";
import filter_icon from "../images/filter.png";
import search_icon from "../images/search.png";
import preview_icon from "../images/preview.png";

export default function About({ data, pageContext }) {
  return (
    <div id="about-page">
      <h2>About this Site</h2>

      <p>
        Welcome to the AUPO Medical Student Exam Question Bank! This resource is intended
        as a compendium of high-quality exam questions for medical student
        end-of-clerkship exams. Questions have been submitted by medical student educators
        around the country and reviewed by the AUPO Medical Student Website Committee.
      </p>

      <h2>How to Use this Site</h2>

      <p>
        Search for questions to add to your custom test by <em>Categories</em> using the
        filter icon <img src={filter_icon} alt="filter icon image" />, <em>Keywords</em>,
        or by clicking the magnifying glass{" "}
        <img src={search_icon} alt="search icon image" /> and typing any text in the
        search bar. You can preview the full question at any time by clicking on the
        preview icon <img src={preview_icon} alt="preview icon image" />.
      </p>

      <p>
        When you find a question that you want to add to your custom test, select the box
        next to it, and click &quot;Add to test&quot; either at the top of the table or
        from the question preview screen. Once added to your custom test, questions will
        be temporarily removed from the list of all questions and will be visible under
        the &ldquo;Custom Test&rdquo; tab. Questions can be reordered by dragging them
        around, or by choosing &ldquo;Randomize&quot;.
      </p>

      <p>
        When you are done, you can download your test. You will get two Microsoft Word
        files, one with the Questions and one with the Answer Key. These can then be
        further formatted as you wish. Please note that the download function is only
        available using Google Chrome, Microsoft Edge, or Opera web browsers. In addition,
        images, which may appear at a low resolution on the screen, will be resized for an
        appropriate resolution when your test is downloaded.
      </p>

      <p>
        A 35-question exam with a variety of questions chosen to represent the AUPO White
        Paper on Medical Student Objectives is available to you for direct download, with
        an additional 15 questions available if you prefer a longer exam.
      </p>

      <p>
        If you would like to contribute questions for review and inclusion in the question
        bank, please contact Dr. Pavlina Kemp at{" "}
        <a href="mailto:pavlina-kemp@uiowa.edu">pavlina-kemp@uiowa.edu</a>. We welcome
        your contributions. If you have any questions about the site itself, please
        contact <a href="mailto:aupo@aao.org">aupo@aao.org</a>.
      </p>

      <p>
        By accessing and using the questions on this website, you are agreeing to keep all
        questions and answers confidential and in a secure location to protect the
        integrity of the exam questions and the work of those who have written them.
      </p>

      <p>
        Thank you!
        <br />
        AUPO Medical Student Educators Council Website Committee
      </p>
    </div>
  );
}

export const query = graphql`
  {
    allSitePage(filter: { path: { eq: "/about/" } }) {
      edges {
        node {
          id
          path
          fields {
            frontmatter {
              description
              keywords
              tabs
              title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
